import PageError from '@kili-technology/pages/dist/page-error/index';
import React, { PureComponent } from 'react';

import { DisplayableError } from '@/services/errors/DisplayableError';

import { sendToDatadog } from '../../datadog';
import { defaultActions, defaultDescription, defaultTitle } from '../../services/errors/constants';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: Error;
};

export class ErrorBoundary extends PureComponent<Props, State> {
  state: State = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: unknown): void {
    sendToDatadog(error, info, 'javascript');
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      const errorAttributes = {
        actions: defaultActions,
        description: defaultDescription,
        title: defaultTitle,
      };

      if (error instanceof DisplayableError) {
        errorAttributes.actions = error.actions;
        errorAttributes.description = error.message;
        errorAttributes.title = error.title;
      }

      return (
        <PageError
          actions={errorAttributes.actions}
          description={errorAttributes.description}
          title={errorAttributes.title}
        />
      );
    }

    return children;
  }
}
