import { useLazyQuery } from '@apollo/client';
import {
  createContext,
  type FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { type Asset } from '@/__generated__/globalTypes';
import {
  type AssetsQuery,
  type CountAssetsQuery,
} from '@/graphql/asset/__generated__/queries.graphql';
import { ASSET_FRAGMENTS } from '@/graphql/asset/fragments';
import { GQL_ASSETS_COUNT_INSPECT, GQL_ASSETS_INSPECT } from '@/graphql/asset/queries';
import { useSearchQuery } from '@/hooks/useAssetsQuery';
import { addErrorNotification } from '@/redux/application/actions';
import { projectIsVideo } from '@/redux/selectors';
import { type State } from '@/redux/types';
import { store } from '@/store';

export type AssetsManager = {
  assets: Asset[];
  invalidateAssets(assetIds?: string[]): void;
  isAssetLoaded(index: number): boolean;
  loadMoreAssets(startIndex: number, stopIndex: number): Promise<void>;
  loading: boolean;
  onAssetsInvalidation: (callback: () => void) => { unsubscribe: () => void };
  totalAssetsCount: number;
};

export const AssetsContext = createContext<AssetsManager | undefined>(undefined);

export const useAssets = () => {
  const assetManager = useContext(AssetsContext);

  if (!assetManager) {
    throw new Error('useAssets must be used within an AssetsProvider');
  }
  return assetManager;
};

export const AssetsProvider: FunctionComponent = ({ children }) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const invalidationListenersRef = useRef<(() => void)[]>([]);
  const invalidationSetRef = useRef(new Set<string>());
  const where = useSearchQuery();
  const isProjectAnonymized = useSelector((state: State) => state.project?.isAnonymized);

  const [countAssets, { data: countData, loading: countLoading }] = useLazyQuery<CountAssetsQuery>(
    GQL_ASSETS_COUNT_INSPECT,
    {
      context: { clientName: 'V2' },
      fetchPolicy: 'no-cache',
      variables: { first: null, skip: null, where },
    },
  );
  const totalAssetsCount = countData?.count ?? 0;

  const shouldDownloadJsonResponse = !projectIsVideo(store.getState());
  const [loadAssets, { data: assetsData, loading: assetsLoading }] = useLazyQuery<AssetsQuery>(
    GQL_ASSETS_INSPECT(ASSET_FRAGMENTS(shouldDownloadJsonResponse)),
    {
      context: { clientName: 'V2' },
      fetchPolicy: 'network-only',
      onError() {
        store.dispatch(addErrorNotification('An error occurred while fetching assets.'));
      },
      variables: { where },
    },
  );

  const loading =
    assetsData === undefined ||
    countData === undefined ||
    isProjectAnonymized === undefined ||
    assetsLoading ||
    countLoading;

  const invalidateAssets = useCallback(
    async (assetIds?: string[]) => {
      if (assetIds !== undefined) {
        assetIds.forEach(assetId => invalidationSetRef.current.add(assetId));
      } else {
        setAssets([]);
      }
      await countAssets();

      // To be sure that assets count has been updated in consumers before calling those callbacks.
      setTimeout(() => {
        invalidationListenersRef.current.forEach(callback => callback());
      }, 50);
    },
    [countAssets],
  );

  const loadMoreAssets = useCallback(
    async (startIndex: number, stopIndex: number) => {
      if (isProjectAnonymized === undefined) {
        return;
      }
      const first = stopIndex - startIndex + 1;
      const skip = startIndex;
      const result = await loadAssets({ variables: { first, skip } });
      const newAssets = (result.data?.assets ?? []) as Asset[];

      setAssets(currentAssets => {
        const sectionLength = stopIndex - startIndex + 1;

        const mergedAssets = currentAssets.slice();

        if (newAssets.length === 0) {
          mergedAssets.splice(startIndex, currentAssets.length - startIndex);
          return mergedAssets;
        }

        const assetsToMerge = newAssets.reduce((acc, newAsset, newAssetIndex) => {
          const duplicatedAssetIndex = mergedAssets.findIndex(asset => asset.id === newAsset.id);
          if (duplicatedAssetIndex !== -1) {
            mergedAssets[duplicatedAssetIndex] = newAsset;
            if (duplicatedAssetIndex !== startIndex + newAssetIndex) {
              return acc.filter(asset => asset.id !== newAsset.id);
            }
            return acc;
          }
          return acc;
        }, newAssets.slice());

        mergedAssets.splice(startIndex, sectionLength, ...assetsToMerge);

        return mergedAssets;
      });
    },
    [isProjectAnonymized, loadAssets],
  );

  const isAssetLoaded = useCallback(
    (index: number) => {
      const asset = assets[index];

      if (asset && invalidationSetRef.current.has(asset.id)) {
        invalidationSetRef.current.delete(asset.id);
        return false;
      }
      return !!asset;
    },
    [assets],
  );

  const onAssetsInvalidation = useCallback((callback: () => void) => {
    const invalidationListeners = invalidationListenersRef.current;

    invalidationListeners.push(callback);

    return {
      unsubscribe: () => {
        invalidationListeners.splice(invalidationListeners.indexOf(callback, 1));
      },
    };
  }, []);

  useEffect(() => {
    if (isProjectAnonymized !== undefined) {
      invalidateAssets();
    }
  }, [invalidateAssets, isProjectAnonymized, where]);

  const assetManager = useMemo(
    () => ({
      assets,
      invalidateAssets,
      isAssetLoaded,
      loadMoreAssets,
      loading,
      onAssetsInvalidation,
      totalAssetsCount,
    }),
    [
      assets,
      invalidateAssets,
      isAssetLoaded,
      loadMoreAssets,
      loading,
      onAssetsInvalidation,
      totalAssetsCount,
    ],
  );

  return <AssetsContext.Provider value={assetManager}>{children}</AssetsContext.Provider>;
};
