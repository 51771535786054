import { type JobResponse, type JsonResponse } from '@kili-technology/types';
import _isEmpty from 'lodash/isEmpty';
import fromEntries from 'object.fromentries';
import { createSelector } from 'reselect';

import { type Asset, InputType, type Label, LabelType } from '@/__generated__/globalTypes';
import { type PropsType as ThumbnailPropsType } from '@/components/ThumbnailsSelect/Thumbnail/Thumbnail';
import { type PropsType as ThumbnailImagePropsType } from '@/components/ThumbnailsSelect/Thumbnail/ThumbnailImage/ThumbnailImage';
import { getResponsesToSet } from '@/services/jobs/setResponse';
import { getFullName } from '@/services/user/format';

import { extractLabelsToDisplayInTable } from './helpers';
import { type AssetLabelState } from './types';

import { printAuthorNameOrModelNameWhenItExists, responseFromResponsesToSet } from '../helpers';
import {
  getClassificationJobs,
  getImageAnnotations,
  getImageRotation,
  getObjectDetectionJobs,
} from '../jobs/helpers';
import { initialState as jobsInitialState } from '../jobs/initialState';
import { type ResponsesTasks } from '../jobs/types';
import { projectInputType, projectJobs, userId as selectUserId } from '../selectors';
import { type State } from '../types';

const assetLabelState = (state: State): AssetLabelState => state.assetLabel;

const assetLabelLabel = createSelector([assetLabelState], assetLabel => assetLabel.label);

export const assetLabelCurrentLabelId = createSelector([assetLabelLabel], label => label?.id);

export const assetLabelLabelAuthor = createSelector([assetLabelLabel], label => label?.author);

export const assetLabelLabelAuthorOrModelName = createSelector([assetLabelLabel], label =>
  label ? printAuthorNameOrModelNameWhenItExists(label) : '',
);

export const assetLabelLabelAuthorName = createSelector([assetLabelLabelAuthor], author =>
  author ? getFullName(author.firstname, author.lastname) : '',
);

export const assetLabelCurrentAuthorId = createSelector(
  [assetLabelLabelAuthor],
  author => author?.id,
);

export const assetLabelAsset = createSelector([assetLabelState], assetLabel => assetLabel.asset);

export const assetLabelCurrentAssetId = createSelector([assetLabelAsset], asset => asset?.id);

export const assetLabelCurrentAssetIdSafely = createSelector([assetLabelAsset], asset => {
  if (asset === undefined) {
    throw new Error('Asset not found');
  }
  return asset.id;
});

export const assetLabelStatus = createSelector([assetLabelAsset], asset => asset?.status);

export const assetLabelExternalId = createSelector([assetLabelAsset], asset => asset?.externalId);

export const assetLabelCurrentIndex = createSelector(
  [assetLabelState],
  ({ asset, viewedAssetIds }) => {
    const assetId = asset?.id;

    return assetId === undefined || !viewedAssetIds.includes(assetId)
      ? 0
      : viewedAssetIds.indexOf(assetId);
  },
);

export const assetLabelViewedAssetIds = createSelector(
  [assetLabelState],
  assetLabel => assetLabel?.viewedAssetIds ?? [],
);

export const selectNonSubmittedViewedAssetIds = createSelector(
  [assetLabelState],
  assetLabel => assetLabel?.nonSubmittedViewedAssetIds ?? [],
);

export const selectAssetLabelJsonMetadata = createSelector(
  [assetLabelState],
  assetLabel => assetLabel.asset?.jsonMetadata,
);

export const assetLabelMetadata = createSelector(
  [selectAssetLabelJsonMetadata],
  assetLabelJsonMetadata => {
    const jsonMetadata = assetLabelJsonMetadata || '{}';
    try {
      return JSON.parse(jsonMetadata);
    } catch {
      return jsonMetadata;
    }
  },
);

export const assetLabelSecondsToLabel = (state: State) =>
  state.assetLabel.label?.secondsToLabel ?? 0;

export const assetLabelLabelType = (state: State) =>
  state.assetLabel.label?.labelType ?? LabelType.DEFAULT;

const getAssetFromOwnPropsImage = (state: State, ownProps: ThumbnailImagePropsType): Asset =>
  ownProps?.asset;
const getAssetFromOwnProps = (state: State, ownProps: ThumbnailPropsType): Asset => ownProps?.asset;

export const getAssetMetadataProcessingParameters = (asset: Asset) => {
  const metadata = asset.jsonMetadata;
  if (_isEmpty(asset) || !metadata) {
    return {};
  }
  try {
    const metadataParsed = JSON.parse(metadata);
    return metadataParsed?.processingParameters ?? {};
  } catch {
    return {};
  }
};

export const assetMetadataProcessingParameters = createSelector(
  [getAssetFromOwnProps],
  getAssetMetadataProcessingParameters,
);

export const assetLatestLabel = createSelector(
  [getAssetFromOwnPropsImage, selectUserId],
  (asset, userId) => {
    if (_isEmpty(asset)) {
      return undefined;
    }
    const orderedUniqLabels = extractLabelsToDisplayInTable(asset, userId);
    const latestLabel: Label | undefined = orderedUniqLabels[0];
    return latestLabel;
  },
);

export const assetLatestResponse = createSelector(
  [assetLatestLabel, projectJobs, projectInputType, assetLabelLabelType],
  (label, jobs, inputType, labelType) => {
    const jobParsed: JobResponse = JSON.parse(label?.jsonResponse ?? '{}');
    const isVideo = inputType === InputType.VIDEO;
    if (isVideo) {
      return fromEntries(
        Object.entries(jobParsed).map(([key, value]) => {
          const keyResponsesToSet = responseFromResponsesToSet(
            getResponsesToSet(jobs, labelType, value),
            jobsInitialState,
          );
          return [key, keyResponsesToSet];
        }),
      );
    }
    const jsonParsed: JsonResponse = JSON.parse(label?.jsonResponse ?? '{}');
    const responsesToSet = getResponsesToSet(jobs, labelType, jsonParsed);
    return responseFromResponsesToSet(responsesToSet, jobsInitialState) as ResponsesTasks;
  },
);

export const assetLatestResponseLabelType = createSelector([assetLatestLabel], label => {
  return (label as Label)?.labelType ?? LabelType.DEFAULT;
});

export const assetImageAnnotations = createSelector([assetLatestResponse], response =>
  getImageAnnotations(getObjectDetectionJobs(response)),
);

export const assetClassificationResponses = createSelector([assetLatestResponse], response =>
  getClassificationJobs(response),
);

export const assetImageRotation = createSelector([assetLatestResponse], response =>
  getImageRotation(response),
);
