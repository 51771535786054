export const PUBLIC_ASSET_ID = 'assetID';
export const PUBLIC_PROJECT_ID = 'projectID';

const getUrlParam = (urlParam: string) => () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(urlParam);
};

export const getPublicAssetId = getUrlParam(PUBLIC_ASSET_ID);

export enum ProjectSegmentEvents {
  ALL_ASSETS_SELECTED = 'All assets selected',
  ANALYTICS_OVERVIEW = 'Analytics - Overview',
  ANALYTICS_PERFORMANCE = 'Analytics - Performance',
  ANALYTICS_QUALITY = 'Analytics - Quality insights',
  ANALYTICS_TEAM_PERFORMANCE_EXPORT = 'Team performance analytics exported',
  ASSETS_ANNOTATED_WITH_COPILOT_OD = 'Assets annotated with Copilot Object Detection model',
  ASSETS_ANNOTATED_WITH_LLM = 'Assets annotated with LLM',
  ASSETS_ASSIGNED = 'Assets assigned',
  ASSETS_DELETED = 'Assets deleted',
  ASSETS_FROM_CLOUD = 'Assets uploaded from cloud',
  ASSETS_FROM_DATASET = 'Assets imported from datasets',
  ASSETS_FROM_LOCAL = 'Assets uploaded from local',
  ASSETS_PRIORITIZED = 'Assets prioritized',
  ASSETS_SEARCHED = 'Assets searched',
  ASSETS_SET_TO_HONEYPOT = 'Assets set to honeypot',
  ASSETS_VIEW_SWITCHED = 'Assets view switched',
  ASSET_SKIPPED = 'Asset skipped',
  BATCH_CLASSIFY = 'Append new labels using batch classify mechanic',
  CREATED_PROJECT = 'Project created',
  CREATE_PROJECT_BACK_STEP_2 = 'Project creation back to step 2',
  CREATE_PROJECT_BACK_STEP_3 = 'Project creation back to step 3',
  CREATE_PROJECT_BACK_STEP_4 = 'Project creation back to step 4',
  CREATE_PROJECT_CREATION_STEP_2 = 'Project creation continue to step 2',
  CREATE_PROJECT_CREATION_STEP_3 = 'Project creation continue to step 3',
  CREATE_PROJECT_CREATION_STEP_4 = 'Project creation continue to step 4',
  CREATE_PROJECT_DEMO = 'Demo project created',
  CREATE_PROJECT_STARTED = 'Project creation started',
  DELETE_PROJECT = 'Project deleted',
  DOCUMENTATION_OPENED = 'Open documentation',
  EXPLORE_BUTTON_CLICKED = 'Explore button clicked',
  EXPORT_DATA = 'Data exported',
  FILTER_APPLIED_ASSIGNEE = 'Assigned to filter applied',
  FILTER_APPLIED_CATEGORIES = 'Categories filter applied',
  FILTER_APPLIED_CONSENSUS = 'Consensus filter applied',
  FILTER_APPLIED_DATE = 'Labeling date filter applied',
  FILTER_APPLIED_EXTERNALID = 'External Id filter applied',
  FILTER_APPLIED_HONEYPOT = 'Honeypot filter applied',
  FILTER_APPLIED_HUMAN_MODEL = 'Human-Model IOU filter applied',
  FILTER_APPLIED_ISSUES_QUESTIONS = 'Issues & Questions filter applied',
  FILTER_APPLIED_LABELED = 'Labeled by filter applied',
  FILTER_APPLIED_METADATA = 'Metadata filter applied',
  FILTER_APPLIED_PREDICTIONS = 'Predictions filter applied',
  FILTER_APPLIED_PRIORITY = 'Priority filter applied',
  FILTER_APPLIED_REVIEWED = 'Reviewed by filter applied',
  FILTER_APPLIED_REVIEW_SCORE = 'Review score filter applied',
  FILTER_APPLIED_SKIPPED = 'Skipped filter applied',
  FILTER_APPLIED_STATUS = 'Status filter applied',
  FILTER_CLEARED_ALL = 'Cleared all filters',
  FILTER_PROJECT_LIST = 'Projects list filtered',
  FILTER_REMOVED_ASSIGNEE = 'Assigned to filter removed',
  FILTER_REMOVED_CATEGORIES = 'Categories filter removed',
  FILTER_REMOVED_CONSENSUS = 'Consensus filter removed',
  FILTER_REMOVED_DATE = 'Labeling date filter removed',
  FILTER_REMOVED_EXTERNALID = 'External Id filter removed',
  FILTER_REMOVED_HONEYPOT = 'Honeypot filter removed',
  FILTER_REMOVED_HUMAN_MODEL = 'Human-Model IOU filter removed',
  FILTER_REMOVED_ISSUES_QUESTIONS = 'Issues & Questions filter removed',
  FILTER_REMOVED_LABELED = 'Labeled by filter removed',
  FILTER_REMOVED_METADATA = 'Metadata filter removed',
  FILTER_REMOVED_PREDICTIONS = 'Predictions filter removed',
  FILTER_REMOVED_PRIORITY = 'Priority filter removed',
  FILTER_REMOVED_REVIEWED = 'Reviewed by filter removed',
  FILTER_REMOVED_REVIEW_SCORE = 'Review score filter removed',
  FILTER_REMOVED_SKIPPED = 'Skipped filter removed',
  FILTER_REMOVED_STATUS = 'Status filter removed',
  FORM_MODIFIED = 'Interface Form modified',
  INCOMPLETE_JOB_NOTIFICATION = 'Incomplete job notification',
  INSTRUCTION_ADDED = 'Instruction added',
  INSTRUCTION_CONSULTED = 'Instruction consulted',
  INSTRUCTION_REMOVED = 'Instruction removed',
  INTERACTIVE_FILTERING_TO_EXPLORE = 'Interactive filtering to Explore',
  INTERACTIVE_SEGMENTATION_CALLED = 'Interactive segmentation called',
  ISSUE_COMMENTED = 'Issue commented',
  ISSUE_CREATED = 'Issue created',
  ISSUE_CREATED_OBJECT = 'Issue created on object',
  ISSUE_SOLVED = 'Issue solved',
  JOB_ADDED = 'Job added',
  JSON_MODIFIED = 'Interface JSON modified',
  LABELED_AGGREGATION_CHANGED = 'Number of labeled assets by labeler aggregation changed',
  LABELING_NEXT_PREVIOUS = 'Next or previous asset clicked on labeling interface',
  NEW_SESSION = 'New LLM Instruction following evaluation session started',
  OBJECT_RIGHT_CLICK = 'Object right click',
  OBJECT_RIGHT_CLICK_BROUGHT_TO_BACK = 'Object brought to back upon right click',
  OBJECT_RIGHT_CLICK_BROUGHT_TO_FRONT = 'Object brought to front upon right click',
  OBJECT_RIGHT_CLICK_CLASS_CHANGE = 'Change object class upon right click',
  OBJECT_RIGHT_CLICK_CREATE_ISSUE = 'Create issue on object upon right click',
  OBJECT_RIGHT_CLICK_CREATE_QUESTION = 'Create question on object upon right click',
  OBJECT_RIGHT_CLICK_DELETE = 'Delete object upon right click',
  OPTION_TOGGLED = 'Option toggled',
  ORG_MEMBER_ADDED = 'Member added to organization',
  ORG_MEMBER_REACTIVATED = 'Member re-activated',
  ORG_MEMBER_ROLE_CHANGED = 'Member role changed',
  ORG_MEMBER_SUSPENDED = 'Member suspended',
  PROJECT_MEMBER_ADDED = 'Member added to a project',
  PROJECT_MEMBER_REMOVED = 'Member removed from project',
  PROJECT_MEMBER_ROLE_CHANGED = 'Member role changed in project',
  PUBLIC_PROJECT = 'Project public enabled',
  QUALITY_SETTINGS_MODIFIED = 'Quality settings modified',
  QUESTION_ASKED = 'Question asked',
  QUESTION_COMMENTED = 'Question commented',
  QUESTION_SOLVED = 'Question solved',
  QUEUE = 'Queue',
  QUEUE_ALL = 'Queue all',
  QUEUE_LABELED = 'Queue labeled',
  QUEUE_REVIEWED = 'Queue reviewed',
  QUEUE_TO_DO = 'Queue to do',
  QUEUE_TO_REVIEW = 'Queue to review',
  REVIEW = 'Label reviewed',
  SEARCH_LABELER = 'Labeler searched',
  SEARCH_MEMBER = 'Member searched',
  SELECT_RELATION_INSTANCE = 'Select relation instance',
  SEND_ASSETS_TO_REVIEW = 'Assets added to review',
  SENT_BACK_TO_QUEUE = 'Asset sent back to queue',
  SHORTCUTS_OPENED = 'Open shortcuts panel',
  SHORTCUT_TOGGLE_KEY_FRAME = 'Shortcut toggle key frame used',
  SHOW_HIDE_CATEGORY_TOGGLED = 'Toggle show hide category button',
  SMART_TRACK = 'Smart Track',
  START_LABELING = 'Labeling started',
  START_REVIEWING = 'Reviewing started',
  STAR_PROJECT = 'Project added to favourites',
  SUBMIT = 'Label submitted',
  TAG_ADDED = 'Tag added',
  TAG_COLOR_CHANGED = 'Tag color changed',
  TAG_CREATED = 'Tag created',
  TAG_DELETED = 'Tag deleted',
  TAG_REMOVED = 'Tag removed',
  TAG_RENAMED = 'Tag renamed',
  TEXT_PROPAGATED = 'Propagate labels',
  TOOLBAR_BUTTON_CLICKED = 'Toolbar button clicked',
  UNSTAR_PROJECT = 'Project removed from favourites',
  UPLOAD_ERRORS_REPORT_DOWNLOADED = 'Upload errors report downloaded',
  VIDEO_OBJECT_TRACKING_CALLED = 'Video object tracking called',
  ZOOM_THUMBNAILS = 'Thumbnails zoomed',
}

export enum DelegatedAccessEvents {
  DATA_CONNECTION_CHECKED = 'Data connection checked',
  DATA_CONNECTION_CREATED = 'Data connection created',
  DATA_CONNECTION_FILTERS = 'Bucket filters applied',
  DATA_CONNECTION_MODIFIED = 'Data connection modified',
  DATA_CONNECTION_REMOVED = 'Data connection removed',
  DATA_CONNECTION_SYNC = 'Data connection sync',
  DATA_INTEGRATION_CHECKED = 'Integration Checked',
  DATA_INTEGRATION_CREATED = 'Integration Created',
  DATA_INTEGRATION_DELETED = 'Integration Deleted',
  DATA_INTEGRATION_MODIFIEID = 'Integration Modified',
  DATA_INTEGRATION_STATUS_CHANGED = 'Integration Status Changed',
}

export enum GenericSegmentEvents {
  API_KEY_CREATED = 'Api key created',
  API_KEY_REVOKED = 'Api key revoked',
  CLICK_ON_BOOK_A_DEMO = 'Click on book a demo',
  CONTACT_SUPPORT_CLICKED = 'Contact support clicked',
  SEARCHED_PROJECT = 'Project searched',
  SETTINGS = 'Settings',
  SIGNUP = 'Signup',
  UPGRADE_PLAN_CLICKED = 'Upgrade to Enterprise CTA clicked',
}

export const SegmentEvents = {
  ...ProjectSegmentEvents,
  ...GenericSegmentEvents,
  ...DelegatedAccessEvents,
};
export type SegmentEvents = ProjectSegmentEvents | GenericSegmentEvents | DelegatedAccessEvents;
