import {
  type AnnotationCategory as ResponseCategory,
  type Annotation,
  type Job,
  MachineLearningTask,
  type Mids,
} from '@kili-technology/types';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { parseToRgb } from 'polished';
import { defaultMemoize } from 'reselect';

import { END_ENTITIES_STEP, START_ENTITIES_STEP } from './constants';

import { ANY_RELATION_VALUE } from '../../components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';
import {
  ANNOTATIONS,
  CATEGORIES,
  CONTENT,
  END_ENTITIES,
  END_OBJECTS,
  JOB_NAME,
  MID,
  NAME,
  START_ENTITIES,
  START_OBJECTS,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { memoizedGetColorForCategories } from '../../components/helpers';
import { type Responses } from '../../redux/jobs/types';

const stackedColors = (colors: string[]): string => {
  if (colors.length === 0) {
    return '';
  }
  if (colors.length === 1) {
    return `-webkit-linear-gradient(top, ${colors[0]}, ${colors[0]})`;
  }
  // Using this multicolor approach: https://blog.prototypr.io/css-only-multi-color-backgrounds-4d96a5569a20
  const colorsWithStop = colors
    .filter((color: string, index: number) => index < colors.length - 1)
    .map((color: string, index: number) => {
      const stop = Math.round((100 * (1 + index)) / colors.length);
      return `${color} ${stop}%, ${colors[index + 1]} ${stop}%`;
    });
  return `-webkit-linear-gradient(top, ${colorsWithStop.join(',')})`;
};

const getBackgroundColor = (
  annotations: { categories: ResponseCategory[]; mid: string }[],
): string => {
  const sortedAnnotations = [...annotations].sort((a, b) => a.mid.localeCompare(b.mid));
  if (!sortedAnnotations || sortedAnnotations.length === 0) return '';
  const colors = sortedAnnotations.map(annotation => {
    const formattedCategories: ResponseCategory[] = _flatten(
      _get(annotation, 'categories')
        .filter(category => category.name)
        .map(category => ({ name: category.name })),
    );
    const jobName = _get(annotation, JOB_NAME);
    return memoizedGetColorForCategories(formattedCategories, jobName);
  });
  const filterColor = (color: string | undefined): string => {
    if (color === undefined) return '';

    const colorWithAlpha = parseToRgb(color);
    const colorStringWithAlpha = `rgba(${colorWithAlpha.red}, ${colorWithAlpha.green}, ${colorWithAlpha.blue}, var(--annotations-color-settings-opacity))`;
    return colorStringWithAlpha;
  };
  const stringColors = colors.map(filterColor);
  return stackedColors(stringColors);
};

export const memoizedGetBackgroundColor = defaultMemoize(getBackgroundColor);

export const getMidsToHideWhenSelectingRelation = (
  selectedRelation: string,
  job: Job | Record<string, never>,
  responses: Responses,
  relationStep: string,
  existingStartEntities?: Mids[],
): string[] => {
  const relation = job?.[CONTENT]?.[CATEGORIES]?.[selectedRelation];
  const startObjects = (relation?.[START_ENTITIES] ?? []).concat(relation?.[START_OBJECTS] ?? []);
  const endObjects = (relation?.[END_ENTITIES] ?? []).concat(relation?.[END_OBJECTS] ?? []);
  const objects = relationStep === START_ENTITIES_STEP ? startObjects : endObjects;
  const objectResponses = {
    ...responses?.[MachineLearningTask.NAMED_ENTITIES_RECOGNITION],
    ...responses?.[MachineLearningTask.OBJECT_DETECTION],
  };
  const midsToHide: string[] = [];
  if (!objectResponses || _isEmpty(objects)) {
    return midsToHide;
  }
  if (objects.includes(ANY_RELATION_VALUE)) return midsToHide;
  Object.entries(objectResponses).forEach(([_, response]) => {
    const annotations = _get(response, ANNOTATIONS, []);
    annotations.forEach((annotation: Annotation) => {
      const mid = _get(annotation, MID);
      const categories = annotation?.[CATEGORIES] || [];
      if (categories.some(category => !objects.includes(_get(category, NAME)))) {
        if (
          relationStep === START_ENTITIES_STEP ||
          (relationStep === END_ENTITIES_STEP &&
            !existingStartEntities?.some(entity => entity.mid === mid))
        ) {
          midsToHide.push(mid);
        }
      }
    });
  });
  return midsToHide;
};

export const getMidsToHideWhenSelectingAnnotation = (
  selectedCategory: string,
  selectedAnnotationMid: string,
  responses: Responses,
): string[] => {
  const objectResponses = {
    ...responses?.[MachineLearningTask.NAMED_ENTITIES_RECOGNITION],
    ...responses?.[MachineLearningTask.OBJECT_DETECTION],
  };
  const midsToHide: string[] = [];
  if (!objectResponses) {
    return midsToHide;
  }
  Object.entries(objectResponses).forEach(([_, response]) => {
    const annotations = _get(response, ANNOTATIONS, []);
    annotations.forEach((annotation: Annotation) => {
      const mid = _get(annotation, MID);
      const categories = annotation?.[CATEGORIES] || [];
      const isCategoriesNotInSelectedCategory = categories.some(category => {
        const isInSelectedCagory = selectedCategory.includes(_get(category, NAME));
        return (isInSelectedCagory && mid !== selectedAnnotationMid) || !isInSelectedCagory;
      });
      if (isCategoriesNotInSelectedCategory) {
        midsToHide.push(mid);
      }
    });
  });
  return midsToHide;
};

export const notificationMessageAllowed = (
  allowedEntities: string[],
  isNamedEntitiesRelation = true,
): { message: string; variant: 'info' } => {
  const typeOfObject = isNamedEntitiesRelation ? 'entity' : 'object';
  return {
    message: `You should select an ${typeOfObject} among ${allowedEntities}`,
    variant: 'info',
  };
};

export const notificationMessageNoDuplicates = (
  isNamedEntitiesRelation = true,
): { message: string; variant: 'info' } => {
  const typeOfObject = isNamedEntitiesRelation ? 'entity' : 'object';
  return {
    message: `You cannot select multiple times the same ${typeOfObject}`,
    variant: 'info',
  };
};
