export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Annotation: [
      'ClassificationAnnotation',
      'ComparisonAnnotation',
      'RankingAnnotation',
      'TranscriptionAnnotation',
      'VideoClassificationAnnotation',
      'VideoObjectDetectionAnnotation',
      'VideoTranscriptionAnnotation',
    ],
    AnnotationValue: [
      'ClassificationAnnotationValue',
      'ComparisonAnnotationValue',
      'ObjectDetectionAnnotationValue',
      'RankingAnnotationValue',
      'TranscriptionAnnotationValue',
    ],
    AssetMetric: ['OpenQuestionsMetric', 'QualityMetric', 'SkippedMetric', 'StatusMetric'],
    CategoryMetric: ['AverageQualityMetric', 'NumberOfAnnotationsMetric', 'NumberOfAssetsMetric'],
    LabelerMetric: [
      'ActionLabelerMetric',
      'AnnotationsLabelerMetric',
      'QualityLabelerMetric',
      'ReviewMetric',
      'TimeLabelerMetric',
    ],
    OrganizationMetric: [
      'OrganizationAnnotationsMetric',
      'OrganizationLabeledAssetsMetric',
      'OrganizationMembersMetric',
      'OrganizationPredictionAndInferenceLabelsMetric',
    ],
    VideoAnnotation: [
      'VideoClassificationAnnotation',
      'VideoObjectDetectionAnnotation',
      'VideoTranscriptionAnnotation',
    ],
    VideoKeyAnnotation: [
      'VideoClassificationKeyAnnotation',
      'VideoObjectDetectionKeyAnnotation',
      'VideoTranscriptionKeyAnnotation',
    ],
  },
};
export default result;
