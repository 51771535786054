import { getTranslation } from '@/i18n/i18n';

import { contactSupportAction, reloadAction } from './constants';
import { type DisplayableErrorOptions, type Action } from './types';

/**
 * @DisplayableError
 *
 * Represents an error that can be displayed on the PageError component.
 *
 * @title - The title to represent of the PageError component (optional).
 * @actions - An array of actions represented by buttons on the PageError component (optional).
 * */
export class DisplayableError extends Error {
  title: string;

  actions: Action[];

  constructor(message?: string, options?: DisplayableErrorOptions) {
    const { actions, title, ...errorOptions } = options ?? {};
    super(message, errorOptions);
    if (!message) this.message = getTranslation('errorPage.description');
    this.title = title ?? getTranslation('errorPage.title');
    this.actions = actions ?? [reloadAction, contactSupportAction];
  }
}
