/* eslint-disable no-await-in-loop */
import { type ApolloClient, type FetchResult, type InMemoryCache } from '@apollo/client';
import { type JsonContent } from '@kili-technology/types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import { batch } from 'react-redux';
import { type Node } from 'slate';

import { sendErrorToDatadog } from '@/datadog';
import { isUrl } from '@/helpers/url';
import { goToConversation } from '@/services/assets/newConversation';
import { store } from '@/store';

import { getMaxBatchSizeInNumberOfAssets, uploadDataViaREST } from './helpers';
import {
  assetLabelCurrentAssetId,
  assetLabelViewedAssetIds,
  selectNonSubmittedViewedAssetIds,
} from './selectors';
import {
  ADD_NON_SUBMITTED_VIEWED_ASSET,
  ASSET_INITIALIZE,
  ASSET_LABEL_INITIALIZE,
  ASSET_UPDATE,
  LABEL_FETCHED,
  LABEL_INITIALIZE,
  LABEL_RESET,
  LABEL_UPDATE,
  REMOVE_NON_SUBMITTED_VIEWED_ASSET,
  UPDATE_FIELD,
  VIEWED_ASSETS_ADD,
} from './slice';
import {
  type StartNewInstrFollowingSessionPayload,
  type AppendManyFramesToDatasetAsynchronouslyPayload,
  type AppendToDatasetPayload,
  type AssetLabelState,
  type BatchAppendToLabelsPayload,
  type FetchAssetPayload,
  type GetAssetPayload,
  type GetNextAssetFromLabelPayload,
  type GetNextAssetFromProjectPayload,
  type GoToFirstLabelPayload,
  type LabelState,
  type ProcessingParameters,
  type RedirectToNextAssetPayload,
  type UpdatePropertiesInAssetPayload,
} from './types';

import {
  type Asset,
  type AssetData,
  type MutationappendManyLabelsArgs,
  type ProjectWhere,
  type CreateLLMAssetData,
} from '../../__generated__/globalTypes';
import { clientQuery } from '../../apollo';
import { readTextFileAsync } from '../../components/DragAndDrop/helpers';
import {
  type AppendManyFramesToDatasetAsynchronouslyMutation,
  type AppendManyFramesToDatasetAsynchronouslyMutationVariables,
  type AppendManyToDatasetFrontendMutation,
  type AppendManyToDatasetFrontendMutationVariables,
} from '../../graphql/asset/__generated__/mutations.graphql';
import {
  GQL_APPEND_MANY_TO_LABELS,
  GQL_APPEND_MANY_TO_DATASET_FRONTEND,
  GQL_DATASET_APPEND_MANY_TO_ASYNCHRONOUSLY,
  GQL_UPDATE_PROPERTIES_IN_ASSETS,
  GQL_CREATE_LLM_ASSET,
} from '../../graphql/asset/mutations';
import {
  GQL_DATASET_GET_ASSET,
  GQL_GET_NEXT_ASSET_FOR_REVIEW_FROM_LABEL,
  GQL_GET_NEXT_ASSET_FOR_REVIEW_FROM_PROJECT,
  GQL_GET_NEXT_ASSET_FROM_LABEL,
  GQL_GET_NEXT_ASSET_FROM_PROJECT,
  GQL_LABELS_GET_AND_COUNT,
} from '../../graphql/asset/queries';
import { GQL_PROJECT } from '../../graphql/project/queries';
import getKiliErrorCode from '../../helpers/getKiliErrorCode';
import { downloadAssetContent, downloadTextAsset } from '../../services/assets/download';
import {
  goToNextRoute,
  goToProjectQueue,
  parseLabelResponse,
} from '../../services/assets/startLabeling';
import { updateFieldGenerics } from '../../types';
import { updateField as zustandUpdateField, useStore } from '../../zustand';
import {
  activateIsLoading,
  activateLabelingFinished,
  addNotification,
  deactivateIsLoading,
  hideMaximumAssetsError,
  updateField as applicationUpdateField,
  updateFieldsInBatch as applicationUpdateFields,
} from '../application/actions';
import {
  applicationAuthenticationToken,
  applicationInExplore,
  applicationIsLabelingOneAsset,
  applicationOriginalUrl,
  applicationReviewForceTableUpdate,
  applicationShouldUpload,
  applicationUploadWarnings,
} from '../application/selectors';
import { ADD_NOTIFICATION } from '../application/slice';
import { type UploadWarning, type UploadWarnings } from '../application/types';
import { resetFrameStateBeforeGoingToNextAsset } from '../label-frame/actions';
import { updateLastSavedJson } from '../label-interface/actions';
import { getLabelOrPredictionForUser } from '../labels/helpers';
import { getOrganization } from '../organization';
import { projectAuthor, projectID as projectProjectID } from '../project/selectors';
import { PROJECT_UPDATE } from '../project/slice';
import { type AppThunk, type EmptyPayloadAction } from '../types';

const APPEND_MANY_TO_DATASET_BATCH_SIZE = 10;
const APPEND_MANY_VIDEO_FRAME_BATCH_SIZE = 1;

type DownloadJsonContentProps = {
  link?: string | null;
  updated: boolean;
  value: JsonContent;
};

export const initializeState = (): EmptyPayloadAction => {
  return ASSET_INITIALIZE();
};

export const labelInitializeState = (): EmptyPayloadAction => {
  return LABEL_INITIALIZE();
};

const getJsonMetadataArray = (
  size: number,
  processingParameters?: ProcessingParameters,
): string[] => {
  return Array(size).fill(processingParameters ? JSON.stringify({ processingParameters }) : '{}');
};

export const appendManyFramesToDatasetAsynchronously = (
  payload: AppendManyFramesToDatasetAsynchronouslyPayload,
): AppThunk => {
  const actionId = `appendManyFramesToDatasetAsynchronously`;
  return async (dispatch, getState) => {
    const { client, dataset, processingParameters, uploadType } = payload;
    const state = getState();
    const projectID = projectProjectID(state);

    const batchSize = Math.min(
      APPEND_MANY_VIDEO_FRAME_BATCH_SIZE,
      getMaxBatchSizeInNumberOfAssets(dataset.contents),
    );

    const contentArray = dataset.contents;
    const externalIdArray = dataset.externalIds;
    const dataArray = dataset.data.slice(-externalIdArray.length);

    batch(() => {
      dispatch(applicationUpdateField({ path: 'uploadCurrentFileIndex', value: 0 }));
      dispatch(hideMaximumAssetsError());
      dispatch(
        applicationUpdateField({ path: 'uploadNumberOfFiles', value: externalIdArray.length }),
      );
      dispatch(applicationUpdateField({ path: 'shouldUpload', value: true }));
    });

    let lastUploadedFileIndex = 0;
    let contentsToUpload: string[] = [];
    let assetIds: string[] = [];
    let externalIdsToUpload: string[] = [];
    if (batchSize > 0) {
      for (
        let currentFileIndex = 0;
        currentFileIndex < contentArray.length;
        currentFileIndex += batchSize
      ) {
        if (!applicationShouldUpload(getState())) {
          break;
        }
        const currentFileName = externalIdArray[currentFileIndex];

        batch(() => {
          dispatch(
            applicationUpdateField({
              path: 'uploadCurrentFileIndex',
              value: currentFileIndex,
            }),
          );
          dispatch(
            applicationUpdateField({ path: 'uploadCurrentFileName', value: currentFileName }),
          );
        });

        let contentArraySlice = contentArray.slice(currentFileIndex, currentFileIndex + batchSize);
        let assetIdArraySlice = null;
        const externalIdArraySlice = externalIdArray.slice(
          currentFileIndex,
          currentFileIndex + batchSize,
        );

        lastUploadedFileIndex = currentFileIndex + contentArraySlice.length;

        if (!dataset.dataIsStringified) {
          const dataSlice = dataArray.slice(
            currentFileIndex,
            currentFileIndex + batchSize,
          ) as File[];
          const contentArrayWithIdsSlice = await uploadDataViaREST(dataSlice, getState, client);
          contentArraySlice = contentArrayWithIdsSlice.map(el => el.url);
          assetIdArraySlice = contentArrayWithIdsSlice.map(el => el.assetId);
        }

        contentsToUpload = contentsToUpload.concat(contentArraySlice);
        if (assetIdArraySlice && assetIdArraySlice.every(id => !!id)) {
          assetIds = assetIds.concat(assetIdArraySlice as string[]);
        }
        externalIdsToUpload = externalIdsToUpload.concat(externalIdArraySlice);
      }
    }

    const idArray = assetIds?.length ? assetIds : undefined;

    await client.mutate<
      AppendManyFramesToDatasetAsynchronouslyMutation,
      AppendManyFramesToDatasetAsynchronouslyMutationVariables
    >({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      mutation: GQL_DATASET_APPEND_MANY_TO_ASYNCHRONOUSLY,
      variables: {
        data: {
          contentArray: contentsToUpload,
          externalIDArray: externalIdsToUpload,
          idArray,
          jsonMetadataArray: getJsonMetadataArray(contentArray.length, processingParameters),
          uploadType,
        },
        where: { id: projectID },
      },
    });

    const uploadHasBeenInterrupted = !applicationShouldUpload(getState());
    if (uploadHasBeenInterrupted) {
      dispatch(
        addNotification({
          message: `Upload interrupted. ${lastUploadedFileIndex} file${
            lastUploadedFileIndex !== 1 ? 's' : ''
          } will be uploaded`,
          variant: 'info',
        }),
      );
    } else {
      const warnings = applicationUploadWarnings(state);
      const numberOfFilesUploaded = contentArray.length - warnings.warnings.length;
      if (!numberOfFilesUploaded) {
        dispatch(
          addNotification({
            message: `Failed to upload the files. Check the documentation for the list of supported file
              types and your asset ID list for possible duplicates`,
            variant: 'warning',
          }),
        );
      }
    }

    // setTimeout send the dispatch at the end of the call stack, so the notification won't be reset when multiple are dispatched
    setTimeout(() => {
      dispatch(
        addNotification({
          message: 'Import just started, you will receive a notification as soon as it is ready.',
          variant: 'info',
        }),
      );
    }, 0);
    dispatch(
      applicationUpdateFields([
        { path: 'shouldUpload', value: false },
        { path: 'uploadCurrentFileIndex', value: null },
        { path: 'uploadCurrentFileName', value: null },
        { path: 'uploadNumberOfFiles', value: null },
      ]),
    );
  };
};

export const resetState = (): AppThunk => {
  return async dispatch => {
    batch(() => {
      dispatch(ASSET_UPDATE(undefined));
      dispatch(LABEL_RESET());
      dispatch(updateLastSavedJson({}));
    });
  };
};

export const getAsset = (payload: GetAssetPayload): AppThunk => {
  return async (dispatch, getState) => {
    const { assetID, client, labelID } = payload;
    const authenticationToken = applicationAuthenticationToken(getState());

    if (!assetID) {
      dispatch(resetState());
      return;
    }

    const asset = await fetchAsset({ assetID, authenticationToken, client });

    if (!asset) {
      dispatch(
        addNotification({
          message: 'Asset not found',
          variant: 'error',
        }),
      );
      return;
    }

    const labelPayload = { ...payload, assetID: asset.id, labelID };
    const label = await getLabelOrPredictionForUser(labelPayload);

    batch(() => {
      dispatch(addViewedAsset(asset.id));
      dispatch(ASSET_UPDATE(asset));
    });

    if (label) {
      const labelResponse = parseLabelResponse(label);
      batch(() => {
        dispatch(LABEL_UPDATE(labelResponse));
        dispatch(updateLastSavedJson(labelResponse.jsonResponse));
      });
    }

    dispatch(LABEL_FETCHED());
    dispatch(getProjectFromAsset(payload, asset?.projectId));
  };
};

export const updatePropertiesInAsset = (
  payload: UpdatePropertiesInAssetPayload,
): AppThunk<Promise<FetchResult<AssetData>>> => {
  const actionId = `updatePropertiesInAsset`;
  const { client, data, where } = payload;
  return async () => {
    return client.mutate({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      mutation: GQL_UPDATE_PROPERTIES_IN_ASSETS,
      variables: { data: [data], where: [where] },
    });
  };
};

export const setAsset = (payload: Asset | undefined) => {
  return ASSET_UPDATE(payload);
};

export const redirectToNextAsset = ({
  authorID,
  inReview,
  nextAssetId,
  projectID,
  pushRoute,
}: RedirectToNextAssetPayload): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const isLabelingOneAsset = applicationIsLabelingOneAsset(state);
    if (isLabelingOneAsset) {
      goToProjectQueue({ projectId: projectID, pushRoute });
      dispatch(activateLabelingFinished(inReview));
      return;
    }

    dispatch(resetFrameStateBeforeGoingToNextAsset());
    goToNextRoute({
      assetId: nextAssetId,
      inReview,
      projectId: projectID,
      pushRoute,
      userID: authorID,
    });
  };
};

export const addViewedAsset = (assetId: string | null): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const viewedAssetIds = assetLabelViewedAssetIds(state);

    const shouldAddNewAsset = assetId && !viewedAssetIds.includes(assetId);

    if (shouldAddNewAsset) dispatch(VIEWED_ASSETS_ADD(assetId));
  };
};

export const addNonSubmittedViewedAsset = (assetId: string | null): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const nonSubmittedViewedAssetIds = selectNonSubmittedViewedAssetIds(state);

    const shouldAddNewAsset = assetId && !nonSubmittedViewedAssetIds.includes(assetId);

    if (shouldAddNewAsset) dispatch(ADD_NON_SUBMITTED_VIEWED_ASSET(assetId));
  };
};

export const removeNonSubmittedViewedAsset = (assetId: string | null): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const nonSubmittedViewedAssetIds = selectNonSubmittedViewedAssetIds(state);

    const shouldRemoveAsset = assetId && nonSubmittedViewedAssetIds.includes(assetId);

    if (shouldRemoveAsset) dispatch(REMOVE_NON_SUBMITTED_VIEWED_ASSET(assetId));
  };
};

export const getNextAssetFromLabel = async (
  payload: GetNextAssetFromLabelPayload,
): Promise<Asset | undefined> => {
  const { inReview, labelAssetIDs, projectId, nonSubmittedAssetIdsWithCurrent, client, skipped } =
    payload;
  const actionId = inReview ? 'getNextAssetForReviewFromLabel' : 'getNextAssetFromLabel';
  const { data } = await clientQuery({
    actionId,
    client,
    clientName: 'V2',
    dispatch: null,
    fetchPolicy: 'no-cache',
    query: inReview ? GQL_GET_NEXT_ASSET_FOR_REVIEW_FROM_LABEL : GQL_GET_NEXT_ASSET_FROM_LABEL,
    variables: {
      labelAssetIDs,
      nonSubmittedViewedAssetIds: nonSubmittedAssetIdsWithCurrent,
      projectId,
      skip: skipped,
    },
    withLoader: false,
  });
  if (inReview) {
    return data?.getNextAssetForReviewFromLabel;
  }

  return data?.getNextAssetFromLabel;
};

export const createNewLlmConversation = async ({
  client,
  variables,
}: {
  client: ApolloClient<InMemoryCache>;
  variables: {
    data: CreateLLMAssetData;
    where: ProjectWhere;
  };
}): Promise<string> => {
  const actionId = 'createLLMAsset';
  const { data } = await client.mutate({
    context: {
      clientName: 'V2',
      headers: {
        actionId,
      },
    },
    fetchPolicy: 'no-cache',
    mutation: GQL_CREATE_LLM_ASSET,
    variables,
  });
  if (data) return data.createLLMAsset.id;

  throw new Error('Error when creating new Llm session.');
};

export const getNextAssetAndLabelFromProject = (
  payload: GetNextAssetFromProjectPayload,
): AppThunk => {
  return async (dispatch, getState) => {
    const { client, inReview, projectID } = payload;

    const state = getState();
    let assetID = assetLabelCurrentAssetId(state);
    const authenticationToken = applicationAuthenticationToken(state);

    const shouldDownloadANewAsset = !assetID || inReview;
    if (shouldDownloadANewAsset) {
      const actionId = inReview ? 'getNextAssetForReviewFromProject' : 'getNextAssetFromProject';
      const query = inReview
        ? GQL_GET_NEXT_ASSET_FOR_REVIEW_FROM_PROJECT
        : GQL_GET_NEXT_ASSET_FROM_PROJECT;

      const response = await clientQuery({
        actionId,
        client,
        clientName: 'V2',
        dispatch,
        query,
        variables: { projectID },
        withLoader: false,
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      if (data?.[actionId] === null) {
        dispatch(addNotification({ message: 'No asset available', variant: 'info' }));
      }

      assetID = data?.[actionId]?.id;

      if (!assetID) {
        dispatch(ASSET_UPDATE(undefined));
        dispatch(updateLastSavedJson({}));
        return;
      }
    }

    if (!assetID) {
      return;
    }

    const payloadWithAssetId = { ...payload, assetID, authenticationToken };
    const asset = await fetchAsset(payloadWithAssetId);
    const newState = getState();
    const inExploreInterface = applicationInExplore(newState);
    const originalUrl = applicationOriginalUrl(newState) ?? '';
    if (inExploreInterface || originalUrl.includes('/dataset')) return;
    const labelPayload = await getLabelOrPredictionForUser(payloadWithAssetId);
    dispatch(ASSET_UPDATE(asset));
    if (labelPayload) {
      batch(() => {
        dispatch(LABEL_UPDATE(parseLabelResponse(labelPayload)));
        dispatch(updateLastSavedJson(parseLabelResponse(labelPayload).jsonResponse));
      });
    }
    dispatch(LABEL_FETCHED());
    if (asset?.content) {
      // if it is a video native, do not pre-download it, as it is not cacheable
      const isVideoNative =
        asset?.jsonMetadata &&
        JSON.parse(asset.jsonMetadata)?.processingParameters?.shouldUseNativeVideo;
      if (!isVideoNative) downloadAssetContent(asset.content, authenticationToken, undefined, true);
    }
    if (asset?.jsonContent && isUrl(asset.jsonContent)) {
      downloadAssetContent(asset.jsonContent, authenticationToken, undefined, true);
    }
  };
};

export const startNewInstrFollowingConversation = (
  payload: StartNewInstrFollowingSessionPayload,
): AppThunk => {
  return async (dispatch, _) => {
    const { inReview, projectID, pushRoute, userID } = payload;

    dispatch(ASSET_LABEL_INITIALIZE());
    dispatch(activateIsLoading());

    const createLLMAssetPayload = {
      client: payload.client,
      variables: {
        data: {
          authorId: userID,
        },
        where: { id: projectID },
      },
    };

    try {
      const assetID = await createNewLlmConversation(createLLMAssetPayload);

      goToConversation({
        assetId: assetID,
        inReview,
        projectId: projectID,
        pushRoute,
        userID,
      });
    } catch (e) {
      const error = e as Error;
      sendErrorToDatadog(error, { message: 'Error when creating new Llm conversation.' });
      dispatch(
        ADD_NOTIFICATION({
          message: 'An error occured during conversation creation.',
          variant: 'error',
        }),
      );
      throw error;
    } finally {
      dispatch(deactivateIsLoading());
    }
  };
};

export const goToFirstLabel = (payload: GoToFirstLabelPayload): AppThunk => {
  return async (dispatch, getState) => {
    const { inReview, projectID, pushRoute, userID } = payload;

    if (payload.assetID) {
      goToNextRoute({
        assetId: payload.assetID,
        inReview,
        projectId: projectID,
        pushRoute,
        userID,
      });
      return;
    }

    dispatch(ASSET_LABEL_INITIALIZE());
    useStore.getState().labelImageSemantic.initialize();
    dispatch(activateIsLoading());

    const assetIDInReduxState = assetLabelCurrentAssetId(getState());
    const shouldFetchANewAsset = !assetIDInReduxState || inReview;

    if (shouldFetchANewAsset) {
      await dispatch(getNextAssetAndLabelFromProject(payload));
    }

    const assetID = assetLabelCurrentAssetId(getState());
    goToNextRoute({
      assetId: assetID,
      inReview,
      projectId: projectID,
      pushRoute,
      userID,
    });
    if (!assetID) {
      dispatch(activateLabelingFinished(inReview));
    } else {
      dispatch(addViewedAsset(assetID));
    }
    dispatch(deactivateIsLoading());
  };
};

export const downloadJsonContent = async (
  urlJsonContent: string | null | undefined,
  authenticationToken?: string | null | undefined,
  abortSignal?: AbortSignal,
  shouldAppendToCache?: boolean,
): Promise<DownloadJsonContentProps> => {
  const jsonContent = {
    link: urlJsonContent,
    updated: false,
    value: [] as Node[],
  };
  if (!urlJsonContent) {
    return jsonContent;
  }
  const responseJson = await downloadTextAsset(
    urlJsonContent,
    authenticationToken ?? '',
    abortSignal,
    shouldAppendToCache,
  ).catch(() =>
    downloadAssetContent(urlJsonContent, authenticationToken ?? '', abortSignal, false)
      .then(response => response && response.blob())
      .then(blob => blob && readTextFileAsync(blob)),
  );
  const parsedJson = JSON.parse(responseJson as string);
  _set(jsonContent, 'value', parsedJson);
  _set(jsonContent, 'updated', true);
  return jsonContent;
};

export const updateJsonContent = async (
  payload: Partial<Asset>,
  authenticationToken?: string | null,
  abortSignal?: AbortSignal,
): Promise<DownloadJsonContentProps | null> => {
  const shouldAppendToCache = !!_get(payload, 'dataIntegrationId', false);
  const initialJsonContent = _get(payload, 'jsonContent', null);
  const shouldUpdateJson = initialJsonContent && !_get(initialJsonContent, 'updated');
  if (shouldUpdateJson) {
    const jsonContent = await downloadJsonContent(
      initialJsonContent,
      authenticationToken ?? '',
      abortSignal,
      shouldAppendToCache,
    );
    return jsonContent;
  }
  return null;
};

export const fetchAsset = async (payload: FetchAssetPayload): Promise<Asset | undefined> => {
  const actionId = `fetchAsset`;
  const { assetID, authenticationToken, client } = payload;
  if (!assetID) {
    return undefined;
  }
  const response = await clientQuery({
    actionId,
    client,
    clientName: 'V2',
    dispatch: null,
    fetchPolicy: 'network-only',
    query: GQL_DATASET_GET_ASSET,
    variables: { assetID },
    withLoader: false,
  });
  const asset = response?.data?.asset;
  if (!asset) return undefined;
  const jsonContent = await updateJsonContent(asset, authenticationToken);
  const assetWithJsonContentUpdated = {
    ...asset,
    jsonContent,
  };
  return assetWithJsonContentUpdated;
};

export const appendToLabelsFromBatch = async (
  payload: BatchAppendToLabelsPayload,
): Promise<void> => {
  const state = store.getState();
  const actionId = `appendManyToLabels`;

  const { authorID, client, jsonResponse = '{}', assetIDs, labelType, where } = payload;

  if (assetIDs.length === 0) return;
  const labelsData = assetIDs.map(assetID => {
    return {
      assetID,
      authorID,
      jsonResponse,
    };
  });

  const variables: MutationappendManyLabelsArgs = {
    data: {
      labelType,
      labelsData,
    },
    where,
  };

  const response = await client.mutate({
    context: { clientName: 'V2', headers: { actionId } },
    fetchPolicy: 'no-cache',
    mutation: GQL_APPEND_MANY_TO_LABELS,
    variables,
  });
  if (response.errors) {
    const kiliErrorCode = getKiliErrorCode(response.errors[0]);
    const author = projectAuthor(state);
    if (!author.email) {
      store.dispatch(
        ADD_NOTIFICATION({
          message: 'No author found for this project, please contact support.',
          variant: 'error',
        }),
      );
      throw new Error('No author found for this project.');
    }

    if (kiliErrorCode === 'licenseLimitation') {
      getOrganization({ client, email: author.email });
    }
  }
};

export const updateField = updateFieldGenerics<AssetLabelState>()(UPDATE_FIELD);

export const setLabel = (payload: {
  client: ApolloClient<InMemoryCache>;
  label: LabelState;
  lastRequestedLabelIdRef?: React.MutableRefObject<string | undefined>;
}): AppThunk => {
  return async dispatch => {
    const { client, label, lastRequestedLabelIdRef } = payload;
    if (label.jsonResponse || _isEmpty(label)) {
      return batch(() => {
        dispatch(updateLastSavedJson(parseLabelResponse(label).jsonResponse));
        dispatch(LABEL_UPDATE(parseLabelResponse(label)));
      });
    }

    if (!label.id) return null;

    zustandUpdateField({
      key: 'isFetchingAnnotation',
      sliceName: 'labelImageSemantic',
      value: true,
    });

    if (lastRequestedLabelIdRef) {
      lastRequestedLabelIdRef.current = label.id;
    }

    const labelWithJsonResponse = await client.query({
      fetchPolicy: 'cache-first',
      query: GQL_LABELS_GET_AND_COUNT,
      variables: {
        first: 1,
        skip: 0,
        where: { id: label.id },
      },
    });

    if (lastRequestedLabelIdRef && lastRequestedLabelIdRef.current !== label.id) return null;

    const jsonResponse = _get(labelWithJsonResponse, ['data', 'data', 0, 'jsonResponse']);
    return batch(() => {
      dispatch(updateLastSavedJson(parseLabelResponse({ ...label, jsonResponse }).jsonResponse));
      dispatch(LABEL_UPDATE(parseLabelResponse({ ...label, jsonResponse })));
      zustandUpdateField({
        key: 'isFetchingAnnotation',
        sliceName: 'labelImageSemantic',
        value: false,
      });
    });
  };
};

export const getProjectFromAsset = (
  payload: FetchAssetPayload,
  assetProjectId: string,
): AppThunk => {
  const actionId = `getProjectFromAsset`;
  return async (dispatch, getState) => {
    const { client } = payload;
    const state = getState();
    const projectId = projectProjectID(state);
    if (!projectId || assetProjectId !== projectId) {
      const assetID = _get(payload, 'assetID');

      const response = await clientQuery({
        actionId,
        client,
        clientName: 'V2',
        dispatch,
        fetchPolicy: 'cache-first',
        query: GQL_PROJECT,
        variables: {
          first: 1,
          skip: 0,
          where: { asset: { id: assetID }, id: assetProjectId },
        },
        withLoader: false,
      });
      const data = response?.data;
      if (!data) return;
      const project = _get(data, 'projects[0]');
      if (project) {
        dispatch(PROJECT_UPDATE(project));
      }
    }
  };
};

export const fetchNewLabels = (payload: {
  assetID: string;
  client: ApolloClient<InMemoryCache>;
  userID: string;
}): AppThunk => {
  return async dispatch => {
    const { client, userID, assetID } = payload;
    const labelPayload = await getLabelOrPredictionForUser({ assetID, client, userID }, 'no-cache');
    if (!labelPayload) return;
    const newLabel = parseLabelResponse(labelPayload);
    batch(() => {
      dispatch(LABEL_UPDATE(newLabel));
      dispatch(updateLastSavedJson(newLabel.jsonResponse));
    });
  };
};

export const appendToDataset = (payload: AppendToDatasetPayload): AppThunk => {
  return async (dispatch, getState) => {
    const { callback, client, data, dataIsStringified, processingParameters } = payload;
    const { contentArray, externalIdArray, rawData } = data;

    if (!contentArray || !externalIdArray) return;

    const batchSize = Math.min(
      APPEND_MANY_TO_DATASET_BATCH_SIZE,
      getMaxBatchSizeInNumberOfAssets(contentArray),
    );

    batch(() => {
      dispatch(applicationUpdateField({ path: 'uploadCurrentFileIndex', value: 0 }));
      dispatch(hideMaximumAssetsError());
      dispatch(applicationUpdateField({ path: 'uploadNumberOfFiles', value: contentArray.length }));
      dispatch(applicationUpdateField({ path: 'shouldUpload', value: true }));
    });

    let hadWarnings = false;
    const successMessage = `New asset${externalIdArray.length > 1 ? 's' : ''} uploaded`;
    const warningMessage = `New asset${
      externalIdArray.length > 1 ? 's' : ''
    } uploaded. Check report for error(s).`;

    let lastUploadedFileIndex = 0;
    if (batchSize > 0) {
      for (
        let currentFileIndex = 0;
        currentFileIndex < contentArray.length;
        currentFileIndex += batchSize
      ) {
        let assetIds: string[] = [];
        if (!applicationShouldUpload(getState())) {
          break;
        }
        const currentFileName = externalIdArray[currentFileIndex];
        batch(() => {
          dispatch(
            applicationUpdateField({
              path: 'uploadCurrentFileIndex',
              value: currentFileIndex,
            }),
          );
          dispatch(
            applicationUpdateField({ path: 'uploadCurrentFileName', value: currentFileName }),
          );
        });
        let contentArraySlice = contentArray.slice(currentFileIndex, currentFileIndex + batchSize);
        let assetIdArraySlice = null;
        const externalIdArraySlice = externalIdArray.slice(
          currentFileIndex,
          currentFileIndex + batchSize,
        );

        lastUploadedFileIndex = currentFileIndex + contentArraySlice.length;

        if (!dataIsStringified) {
          const dataSlice = rawData.slice(currentFileIndex, currentFileIndex + batchSize);
          const contentArrayWithIdsSlice = await uploadDataViaREST(
            dataSlice as File[],
            getState,
            client,
          );
          contentArraySlice = contentArrayWithIdsSlice.map(el => el.url);
          assetIdArraySlice = contentArrayWithIdsSlice.map(el => el.assetId);
          if (assetIdArraySlice && assetIdArraySlice.every(id => !!id)) {
            assetIds = assetIds.concat(assetIdArraySlice as string[]);
          }
        }

        const idArray = assetIds?.length ? assetIds : undefined;

        const projectID = projectProjectID(getState());

        const mutation = {
          context: { clientName: 'V2' },
          mutation: GQL_APPEND_MANY_TO_DATASET_FRONTEND,
          variables: {
            data: {
              contentArray: contentArraySlice.map(c => c ?? ''),
              externalIDArray: externalIdArraySlice,
              idArray,
              isHoneypotArray: Array(contentArraySlice.length).fill(false),
              jsonContentArray: Array(contentArraySlice.length).fill(''),
              jsonMetadataArray: getJsonMetadataArray(
                contentArraySlice.length,
                processingParameters,
              ),
              statusArray: Array(contentArraySlice.length).fill('TODO'),
            },
            where: { id: projectID },
          },
        };

        const mutationResult = await client.mutate<
          AppendManyToDatasetFrontendMutation,
          AppendManyToDatasetFrontendMutationVariables
        >(mutation);
        const errors = mutationResult?.errors;
        const appendManyToDatasetFrontendData = mutationResult?.data?.appendManyToDatasetFrontend;
        const previousUploadWarnings = applicationUploadWarnings(getState());
        const warning: UploadWarnings = {
          numberOfUploadedAssets:
            (appendManyToDatasetFrontendData?.numberOfUploadedAssets ?? 0) +
            (previousUploadWarnings?.numberOfUploadedAssets ?? 0),
          warnings: [...previousUploadWarnings.warnings],
        };

        if (errors?.length) {
          batch(() => {
            dispatch(applicationUpdateField({ path: 'shouldUpload', value: false }));
            dispatch(
              addNotification({
                message: warningMessage,
                variant: 'warning',
              }),
            );
          });
        }

        if (appendManyToDatasetFrontendData?.warnings?.length) {
          hadWarnings = true;
          const newWarnings = appendManyToDatasetFrontendData.warnings.filter(w => !!w);
          warning.warnings = [...warning.warnings, ...(newWarnings as UploadWarning[])];
        }

        dispatch(
          applicationUpdateFields([
            {
              path: 'uploadWarnings',
              value: warning,
            },
            { path: 'uploadCurrentFileIndex', value: 0 },
          ]),
        );
        callback?.(currentFileIndex + batchSize);
      }
    }

    const uploadHasBeenInterrupted = !applicationShouldUpload(getState());
    if (uploadHasBeenInterrupted) {
      dispatch(
        addNotification({
          message: `Upload interrupted. ${lastUploadedFileIndex} file${
            lastUploadedFileIndex !== 1 ? 's' : ''
          } uploaded`,
          variant: 'info',
        }),
      );
    } else {
      dispatch(
        addNotification({
          message: hadWarnings ? warningMessage : successMessage,
          variant: hadWarnings ? 'warning' : 'success',
        }),
      );
    }
    const reviewForceTableUpdate = !applicationReviewForceTableUpdate(getState());
    dispatch(
      applicationUpdateField({ path: 'reviewForceTableUpdate', value: reviewForceTableUpdate }),
    );
    dispatch(
      applicationUpdateFields([
        { path: 'shouldUpload', value: false },
        { path: 'uploadCurrentFileIndex', value: null },
        { path: 'uploadCurrentFileName', value: null },
        { path: 'uploadNumberOfFiles', value: null },
      ]),
    );
  };
};
