import { Synchro, Support, Tag } from '@kili-technology/icons';

import { getTranslation } from '@/i18n/i18n';
import { store } from '@/store';

import { type Action } from './types';

export const defaultTitle = getTranslation('errorPage.title');
export const defaultDescription = getTranslation('errorPage.description');

export const reloadAction: Action = {
  label: getTranslation('errorPage.actionsLabel.reloadPage'),
  onClick: () => window.location.reload(),
  startIcon: <Synchro />,
};

export const contactSupportAction: Action = {
  label: getTranslation('errorPage.actionsLabel.contactSupport'),
  onClick: () => {
    window.location.href = `mailto:support@kili-technology.com`;
  },
  startIcon: <Support />,
  variant: 'secondary' as const,
};

export const addLabelingJobsAction: Action = {
  label: getTranslation('errorPage.actionsLabel.addLabelingJobs'),
  onClick: () => {
    const projectId = store.getState().project.id;
    window.location.href = `/label/projects/${projectId}/menu/settings/interface`;
  },
  startIcon: <Tag />,
};

export const defaultActions: Action[] = [contactSupportAction, reloadAction];
