import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { type WithStyles } from '@material-ui/core/styles';
import FailureIcon from '@material-ui/icons/Cancel';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import DownloadIcon from '@material-ui/icons/GetApp';

import { type styles } from './NotificationRow.style';

import { NotificationStatus } from '../../../../__generated__/globalTypes';
import { downloadBucketSignedExportUrl } from '../../../../services/assets/download';

type PropsType = {
  message: string;
  status: string;
  url: string;
} & WithStyles<typeof styles>;

const NotificationRow = ({ classes, message, status, url }: PropsType): JSX.Element => {
  return (
    <MenuItem data-cy="cypress-notification">
      {status === NotificationStatus.FAILURE && (
        <IconButton data-cy="notification-failure">
          <FailureIcon className={classes.failureIcon} />
        </IconButton>
      )}
      {status === NotificationStatus.PENDING && (
        <CircularProgress
          className={classes.pendingIcon}
          data-cy="notification-pending"
          size={20}
        />
      )}
      {status === NotificationStatus.SUCCESS && (
        <IconButton data-cy="notification-success">
          <SuccessIcon className={classes.successIcon} />
        </IconButton>
      )}
      <div className={classes.message}>
        <Typography data-cy="notification-message" variant="body1">
          {message}
        </Typography>
      </div>
      {url && (
        <IconButton
          className={classes.container}
          data-cy="download-button"
          data-testid="download-button"
          onClick={() => downloadBucketSignedExportUrl(url)}
        >
          <DownloadIcon />
        </IconButton>
      )}
    </MenuItem>
  );
};

export default NotificationRow;
